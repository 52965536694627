// Generated by Framer (cd03546)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Gd31Ohovo"];

const variantClassNames = {Gd31Ohovo: "framer-v-110y9yn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Gd31Ohovo", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-KEdtU", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-110y9yn", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Gd31Ohovo"} ref={ref} style={{...style}}><SVG className={"framer-12tihe4"} data-framer-name={"shape-arrow 8"} layout={"position"} layoutDependency={layoutDependency} layoutId={"OJOJB_8qD"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 40 40\"><path d=\"M 21.325 38.36 L 38.413 21.271 C 39.146 20.539 39.146 19.352 38.413 18.62 C 37.681 17.887 36.494 17.887 35.762 18.62 L 21.874 32.508 L 21.874 2.965 C 21.874 1.929 21.034 1.09 19.999 1.09 C 18.963 1.09 18.124 1.929 18.124 2.965 L 18.124 32.507 L 4.237 18.62 C 3.504 17.887 2.317 17.887 1.585 18.62 C 0.853 19.352 0.853 20.539 1.585 21.271 L 18.673 38.36 C 19.406 39.092 20.593 39.092 21.325 38.36 Z\" fill=\"rgb(33,33,33)\"></path></svg>"} svgContentId={1106993449} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-KEdtU [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KEdtU .framer-die04j { display: block; }", ".framer-KEdtU .framer-110y9yn { height: 40px; overflow: hidden; position: relative; width: 40px; }", ".framer-KEdtU .framer-12tihe4 { flex: none; height: 40px; left: 0px; position: absolute; top: 0px; width: 40px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerlhBGXS3Xx: React.ComponentType<Props> = withCSS(Component, css, "framer-KEdtU") as typeof Component;
export default FramerlhBGXS3Xx;

FramerlhBGXS3Xx.displayName = "Icon Arrow 40";

FramerlhBGXS3Xx.defaultProps = {height: 40, width: 40};

addFonts(FramerlhBGXS3Xx, [])